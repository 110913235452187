import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import ImportService from '@/services/import.service'
import DateRange from '@/components/DateRange/DateRange.vue'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import { Colors } from '@/classes/colors/Colors'
import EventBus from '@/util/EventBus'
import { NumberUtils } from '../../../../../util/NumberUtils'

export default {
  name: 'MyImports',
  components: { HeaderTopDashboard, StatsTitle, DateRange },
  data: function () {
    return {
      tab: [],
      content: [],
      options: {},
      loading: false,
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      selected: [],
      totalItems: 0,
      itemSelected: {},
      globalControl: new GlobalControl(),
      startDate: undefined,
      endDate: undefined,
      modal: false,
      editedItem: {
      },
      defaultItem: {
      },
      data: [],
      ready: true,
      debouncedInput: undefined,
      timeout: undefined,
      refresh: undefined,
      statusesColors: Colors.statusesColors,
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('Id'), align: 'start', value: 'id', sortable: false },
        { text: this.$t('Fecha'), align: 'start', value: 'created_at' },
        { text: this.$t('Archivo'), align: 'start', value: 'original_filename' },
        { text: this.$t('Procesados'), align: 'start', value: 'processed' },
        { text: this.$t('Existentes'), align: 'start', value: 'existing' },
        { text: this.$t('Estado'), align: 'start', value: 'status_id' },
        { text: this.$t('Acciones'), value: 'actions', sortable: false },
      ]
    },
    params (nv) {
      return {
        ...this.options,
        searchTerm: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
  },
  watch: {
    params: {
      handler () {
        this.getMyImports()
      },
      deep: true,
    },
  },
  methods: {
    nanOrNumber (value) {
      return NumberUtils.nanOrNumber(value)
    },
    calculateImportProgress (item) {
      if (!item.lines) {
        return 0
      }
      return Math.round(item.processed / item.lines * 100)
    },
    selectDates (dates) {
      this.startDate = dates[0]
      this.endDate = dates[1]
      this.getMyImports()
    },

    getMyImports () {
      EventBus.$emit('showLoading', true)
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }

      this.loading = true

      ImportService.getMyImports(params)
        .then(
          (response) => {
            if (JSON.stringify(this.content) !== JSON.stringify(response.contact.data)) {
              this.content = response.contact.data
              this.itemsPerPage = parseInt(response.contact.per_page)
              this.page = response.contact.current_page
              if (response.contact.current_page > response.contact.last_page) {
                this.page = response.contact.last_page
              }
              this.totalItems = response.contact.total
            }
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },

    deleteItem (item) {
      this.editedIndex = this.content.indexOf(item)
      this.editedItem = Object.assign({}, this.content[this.editedIndex])
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      ImportService.delete(this.editedItem.id)
      .then(
        () => {
          this.dialogDelete = false
          this.getMyImports()
        },
        () => { },
      )
      .finally(() => {
        this.loading = false
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    syncNow (item) {
      this.editedIndex = this.content.indexOf(item)
    },
    viewItem (item) {
      this.itemSelected = item
      this.modal = true
    },
    sync (item) {
      this.editedIndex = this.content.indexOf(item)
    },
  },
  created () {
    this.refresh = setInterval(() => {
      const existNotFinished = this.content.filter((object) => {
        return object.status_id !== 5
      })
      if (existNotFinished.length > 0) {
        this.getMyImports()
      }
    }, 10000)
  },
  mounted () {
    this.startDate = this.globalControl.initDate
    this.endDate = this.globalControl.endDate
  },
  beforeDestroy () {
    clearInterval(this.refresh)
  },
}
